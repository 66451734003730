import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "../../api/endpoint";
import { makeRequest } from "../../api/api";
import { toast } from 'react-hot-toast';

export const fetchTenderStat = createAsyncThunk(
  "tender/fetchTenderStat",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.tenderStat(id), "GET");
      return response.data;
    } catch (error) {
      toast.error(error.error ?? "Something went wrong");
      return rejectWithValue(error);
    }
  }
);

export const fetchBidderDetail = createAsyncThunk(
  "tender/fetchBidderDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.bidderDetail(id), "GET");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadTenderDocument = createAsyncThunk(
  "tender/uploadTenderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadTenderDocument(id),"POST",data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadExistingBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadExistingBidderDocument(id),"PUT", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadNewBidderDoc = createAsyncThunk(
  "tender/uploadBidderDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(endpoint.uploadNewBidderDocument(id),"POST", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTenderInfo = createAsyncThunk('tender/fetchTenderInfo',
    async (id, {rejectWithValue}) => {
        try {
            const response = await makeRequest(endpoint.tenderInfo(id), "GET");
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const downloadFolder = createAsyncThunk('tender/downloadfolder',
  async (url, {rejectWithValue}) => {
      try {
          const response = await makeRequest(endpoint.downloadFolder(url), "GET");
          return response.data;
      } catch (error) {
          return rejectWithValue(error)
      }
  }
)

const tenderDetailSlice = createSlice({
  name: "tenderDetail",
  initialState: {
    // TenderStat Detail
    tenderStat: "idle",
    tenderStatDetail: {},
    // Bidder Details
    bidderDetailStatus: "idle",
    bidderDetail: [],
    // Tender Info
    tenderInfo: "idle",
    tenderInfoDetail: {},
  },
  reducers: {
    updateTenderDocCount: (state, action) => {
      return {
        ...state,
        tenderStatDetail: {
          ...state.tenderStatDetail,
          total_tender_documents: action.payload,
        },
        tenderInfoDetail: {
          ...state.tenderInfoDetail,
          num_tender_docs: action.payload,
        },
      };
    },
    updateExistingBidderCount: (state, action) => {
      const { id, num_docs } = action.payload;
      const bidder = state.bidderDetail.find(bidder => bidder.id === id);
      if (bidder) {
        bidder.num_docs = num_docs;
      }
    },
    updateNewBidder: (state, action) => {
      state.bidderDetail.push(action.payload);
      if (state.tenderStatDetail.total_bidder) {
        state.tenderStatDetail.total_bidder += 1;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Tender Stat
      .addCase(fetchTenderStat.pending, (state) => {
        state.tenderStat = "loading";
        state.tenderStatDetail = {};
      })
      .addCase(fetchTenderStat.fulfilled, (state, action) => {
        state.tenderStat = "succeeded";
        state.tenderStatDetail = action.payload ?? {};
      })
      .addCase(fetchTenderStat.rejected, (state) => {
        state.tenderStat = "failed";
        state.tenderStatDetail = {};
      })

      // Tender Info
      .addCase(fetchTenderInfo.pending, (state) => {
        state.tenderInfo = "loading";
        state.tenderInfoDetail = {};
      })
      .addCase(fetchTenderInfo.fulfilled, (state, action) => {
        state.tenderInfo = "succeeded";
        state.tenderInfoDetail = action.payload;
      })
      .addCase(fetchTenderInfo.rejected, (state) => {
        state.tenderInfo = "failed";
        state.tenderInfoDetail = {};
      })      

      // Bidder Detail
      .addCase(fetchBidderDetail.pending, (state) => {
        state.bidderDetailStatus = "loading";
        state.bidderDetail = [];
      })
      .addCase(fetchBidderDetail.fulfilled, (state, action) => {
        state.bidderDetailStatus = "succeeded";
        state.bidderDetail = action.payload?.bidder_details ?? [];
      })
      .addCase(fetchBidderDetail.rejected, (state) => {
        state.bidderDetailStatus = "failed";
        state.bidderDetail = [];
      })

  },
});

export const { updateTenderDocCount, updateExistingBidderCount, updateNewBidder } = tenderDetailSlice.actions;

export default tenderDetailSlice.reducer;
