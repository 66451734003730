const pqrLists = [
    "Name of the bidders (Name of the Company)",
    "Address of the Bidder",
    "Login E-Mail id for portal",
    "Number of years in industry",
    "Indian oil vendor code if available",
    "Correspondence email id as per portal",
    "Correspondence name as per portal",
    "Contact Number of the Bidder",
    "Registration Number of the Company",
    "GST Number",
    "PAN Number",
    "Type of Business (e.g., Sole Proprietorship, Partnership, Private Limited, etc.)",
    "Authorized Signatory Name",
    "Authorized Signatory Designation",
    "Bank Account Details (for payments)",
    "Previous Contracts with Indian Oil (if any)",
    "Total Annual Turnover for the Last Financial Year",
    "Number of Employees",
    "Industry Certifications (ISO, etc.)",
    "Products/Services Offered",
    "References or Client Testimonials",
    "Company Website",
    "Company Profile Document",
    "Any Legal Disputes or Litigations",
    "Financial Statements for the Last 3 Years",
    "Details of Major Clients",
    "Compliance with Environmental and Safety Regulations"
];

export default pqrLists