import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
  Input,
  Typography ,
} from "@material-tailwind/react";

import { MdArrowBackIosNew } from "react-icons/md";


export function PortalsModal({
  portalsModalStatus,
  setPortalsModalStatus,
  setSelectOptionStatus,
}) {
  const handleOpen = (value) => setPortalsModalStatus(value);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleBack = () => {
    setPortalsModalStatus(false);
    setSelectOptionStatus(true);
  };

  const data = [
    {
      portal: "Government e-Marketplace (GeM)",
    },
    {
      portal: "National Informatics Centre (NIC) eProcurement System",
    },
    {
      portal: "Indian Oil Corporation Limited (IOCL) e-Tendering Portal",
    },
    {
      portal: "Tendermitra Portal",
    },
  ];

  return (
    <>
      <Dialog open={portalsModalStatus} handler={handleOpen}
      dismiss={{
        outsidePress: false,
      }}
      >
        <div className="flex items-center justify-start gap-40">
          {/* <img
            onClick={handleBack}
            className="w-8 h-8 cursor-pointer ml-4"
            src={BackIcon}
          /> */}

          <div onClick={handleBack} className="cursor-pointer ml-2">
            <MdArrowBackIosNew  size={25}/>
          </div>

          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            Select Portal
          </DialogHeader>
        </div>
        <DialogBody className="cursor-pointer">
          {/* --------------------- */}
          {/* <ul >
            {data.map((item, index) => (
              <li key={index} 
              className={`mb-3 ${selectedItem === index ? 'bg-green-300' : ''} hover:bg-sky-700`}
              onClick={() => setSelectedItem(index)}
              >
                <p
                  className="w-full text-left text-black-500 font-thin"
                  variant="text"
                  
                >
                  {item.portal}
                </p>
              </li>
            ))}
          </ul> */}

          {/* <div className="w-72"> */}
          <Select label="Select Portals">
            {data.map((item, index) => (
              <Option key={index} value={item.portal}>
                {item.portal}
              </Option>
            ))}
          </Select>
          {/* </div> */}

          <div className="mt-8 flex flex-col gap-8 text-black">
            <div>
              <Input label="Enter User ID" className="text-black"/>
            </div>
            <div >
              <Input type="password" label="Password" />
              {/* <Typography
                variant="small"
                color="gray"
                className="mt-2 flex items-center gap-1 font-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="-mt-px h-4 w-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
                Use at least 8 characters, one uppercase, one lowercase and one number.
              </Typography> */}
            </div>
            {/* className="w-72" */}
            <div>
              <Input label="Enter Tender ID" />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleOpen(null)}
          >
            <span>Submit</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
