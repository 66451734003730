import React, { useEffect, useState } from "react";
import HomeTopbar from "../../container/HomeContainer/HomeTopbar";
import HomeTable from "../../container/HomeContainer/HomeTable";
import { fetchTenderDetail } from "../../features/TenderDetail/TenderDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTenders } from "../../features/Tender/TenderSlice";

function HomePage() {
  const { tenderFecthStatus, tenderArr } = useSelector((state) => state.tender);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllTenders());
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-[30px]">
      <div className="flex-none pl-4 pr-4 flex items-center justify-between">
        <span className="text-[#8d6641] font-bold text-2xl">All Tenders</span>
        <span className="text-[#33ACFF] font-bold">Count: {tenderArr?.length}</span>
      </div>
      <div className="flex-grow w-full overflow-y-auto shadow-xl">
        <HomeTable data={tenderArr} status={tenderFecthStatus} />
      </div>
    </div>
  );
}

export default HomePage;
