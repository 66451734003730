// import React, {} from 'react'
// import UserProfile from '../../Components/UserProfile'
// import TenderStats from './TenderStats'
// import { IconButton } from "@material-tailwind/react";
// import { MdOutlineArrowBackIosNew } from "react-icons/md";
// import { useNavigate } from 'react-router-dom';

// function TenderTopbar() {
//   const navigate = useNavigate()



//   return (
//     <div className='flex gap-10 items-center justify-between w-full'>
      
//       <div className='flex items-center gap-14'>
//         <IconButton onClick={() => navigate("/home")} variant="text" className=''>
//           <MdOutlineArrowBackIosNew size={22}/>
//         </IconButton>

//         <TenderStats />
//       </div>

//       <div className='flex items-start h-full w-[100px]'>
//         <UserProfile />
//       </div>

//     </div>
//   )
// }

// export default TenderTopbar



import React, {} from 'react'
import UserProfile from '../../Components/UserProfile'
import TenderStats from './TenderStats'
import TenderBreadCrumb from './TenderBreadCrumb';
import { IconButton } from "@material-tailwind/react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import TenderDocument from './TenderDocument';
import warningIcon from "../../Assets/Images/View-Tender/warning.svg"

function TenderTopbar() {
  const navigate = useNavigate()



  return (
    <div className='w-full flex flex-col gap-5'>
      <TenderStats />
      <TenderBreadCrumb />
      <div className='flex items-center justify-start gap-4 text-[#242325] font-semibold bg-[#E5F7F8] py-3 px-4 shadow-md'>
      <img src={warningIcon} alt="warning" />
        Note: Please refrain from uploading any new bidder documents while the consolidated report is in progress.
      </div>
      <TenderDocument />
    </div>
  )
}

export default TenderTopbar