import React , {useState} from 'react'
import TenderTopbar from '../../container/ViewTender/TenderTopbar'
import TenderBottombar from '../../container/ViewTender/TenderBottombar'

const ViewTenderDetailPage = () => {
  const [uploadDocumentModalStatus, setUploadDocumentModalStatus] = useState(false);
  const [doctext, setDocText] = useState('');

  return (
    <div className='w-full h-full flex flex-col gap-[45px] px-4 py-2'>
      <div className='w-full h-full flex flex-col gap-10'>
        <div className='flex-none'>
          <TenderTopbar />
        </div>
        <div className="flex-grow w-full overflow-y-auto">
          <TenderBottombar uploadDocumentModalStatus={uploadDocumentModalStatus} setUploadDocumentModalStatus={setUploadDocumentModalStatus} doctext={doctext} setDocText={setDocText} />
        </div>
      </div>
    </div>
  )
}

export default ViewTenderDetailPage