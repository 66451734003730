import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginBGPage from "../../Assets/Images/Login/Login_Page.png"
import headingLogo from "../../Assets/Images/Login/tender.svg"
import { toast } from 'react-hot-toast';
import { Button } from "@material-tailwind/react";
import { loginUser } from "../../features/Login/UserSlice";
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

const LoginContainer = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());
    console.log(values);

    try {
      setLoginLoading(true);
      const resultAction = await dispatch(loginUser(values));
      const user = unwrapResult(resultAction);
      if (user?.token) {
        toast.success('Successfully logged in');
        localStorage.setItem("Authorization", user?.token);
        navigate("/home");
      } else {
        toast.error('No token received');
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <div className="relative font-[sans-serif] min-h-screen flex flex-col items-center justify-center py-6 px-4">
      <div
        className="absolute inset-0 bg-cover bg-center opacity-100"
        style={{ backgroundImage: `url(${loginBGPage})` }}
      />
      <div className="relative z-10 mb-8">
        <img src={headingLogo} alt="logo" className="w-12 mx-auto block" />
        <p className="text-[#02154F] font-bold mt-2 text-xl">
          GEN AI Powered Tender Evaluation
        </p>
      </div>

      <div className="relative z-10 max-w-md w-full">
        <div className="p-8 rounded-2xl bg-white shadow">
          <h2 className="text-gray-800 text-center text-2xl font-bold">Sign in</h2>
          <form className="mt-8 space-y-4" onSubmit={handleLogin}>
            {/* Username Field */}
            <div>
              <label className="text-gray-800 text-sm mb-2 block">User name</label>
              <div className="relative flex items-center justify-between">
                <input
                  name="username"
                  type="text"
                  required
                  className="pr-10 w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                  placeholder="Enter user name"
                  disabled={loginLoading}
                />
                {/* User Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-4 h-4 absolute right-4"
                  viewBox="0 0 24 24"
                >
                  <circle cx="10" cy="7" r="6"></circle>
                  <path
                    d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                  ></path>
                </svg>
              </div>
            </div>

            {/* Password Field */}
            <div>
              <label className="text-gray-800 text-sm mb-2 block">Password</label>
              <div className="relative flex items-center">
                <input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  required
                  className="pr-10 w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                  placeholder="Enter password"
                  disabled={loginLoading}
                />
                {/* Eye Icon for Toggle */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-4 h-4 absolute right-4 cursor-pointer"
                  viewBox="0 0 128 128"
                  onClick={togglePasswordVisibility}
                >
                  <path
                    d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                  ></path>
                </svg>
              </div>
            </div>

            {/* Remember Me & Forgot Password */}
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="cursor-pointer h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm text-gray-800">
                  Remember me
                </label>
              </div>
              <div
                className="text-sm text-blue-600 hover:underline font-semibold cursor-pointer"
                onClick={() => navigate('/forgot_password')}
              >
                Forgot your password?
              </div>
            </div>

            {/* Submit Button */}
            <div className="!mt-8">
              <Button
                color="blue"
                type="submit"
                className="w-full py-3 px-4 text-sm rounded-lg text-white normal-case text-center"
                loading={loginLoading}
              >
                {loginLoading ? 'Signing in...' : 'Sign in'}
              </Button>
            </div>

            {/* Register Link */}
            <div className="text-gray-800 text-sm !mt-8 text-center flex">
              Don't have an account?{' '}
              <div
                onClick={() => navigate("/register")}
                className="cursor-pointer text-blue-600 hover:underline ml-1 whitespace-nowrap font-semibold"
              >
                Register here
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
