import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const ProcessedPQRList = ({processedPQR, setProcessedPQR, processedPQRData}) => {
  const handleOpen = () => setProcessedPQR(!processedPQR);
  // console.log(processedPQRData)

  return (
    <>
      <Dialog open={processedPQR} handler={handleOpen}>
        <DialogHeader className="flex justify-center">Generated PQR Data</DialogHeader>
        <DialogBody className="">
            <div className="flex flex-col gap-4 text-[#242325] text-base font-normal max-h-[60vh] overflow-y-scroll">
                {
                    processedPQRData?.map((data, index) => {
                        const [key, value] = Object.entries(data)[0];
                        return (
                            <div key={index} className="flex flex-col">
                                <span>{key ?? "NA"}</span>
                                <span>{value ?? "NA"}</span>
                            </div>
                        )
                    })
                }
            </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ProcessedPQRList;
