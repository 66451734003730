import React from 'react'
import DocumentUploadButton from '../../Components/DocumentUploadButton'
import TenderDocument from './TenderDocument'
import BidderTable from './BidderTable'
import UploadDocument from "../../Components/modals/UploadDocument"

const TenderBottombar = ({uploadDocumentModalStatus,setUploadDocumentModalStatus , doctext , setDocText}) => {
  return (
    <div className='h-full w-full flex flex-row flex-wrap lg:flex-nowrap gap-10 overflow-visible lg:overflow-hidden'>
      <div className='bg-[#FFFFFF] overflow-hidden flex-grow w-full lg:w-auto'>
          <BidderTable />
      </div>
    </div>

  )
}

export default TenderBottombar