// import React, {useEffect, useState, useRef } from 'react'
// import { toast } from 'react-hot-toast';
// import Skeleton from 'react-loading-skeleton';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateTenderDocCount, fetchTenderStat } from '../../features/TenderDetail/TenderDetailSlice';
// import { useParams } from 'react-router-dom';

// const TenderStats = () => {
//     const [statData, setStatData] = useState([
//         {label: "Tender Number", value: ""},
//         {label: "Tender Name", value: ""},
//         {label: "Total Bidders", value: ""},
//         {label: "Total Tender Documents", value: ""},
//         {label: "Status", value: ""},
//     ])
//     let { id } = useParams();

//     const {tenderStat, tenderStatDetail} = useSelector((state) => state.tenderDetail)


//     const dispatch = useDispatch()
  
//     useEffect(() => {
//       dispatch(fetchTenderStat(id))
//     }, [])

//     const hasEffectRun = useRef(false);
//     useEffect(() => {
//         if(tenderStat === "succeeded"){
//             updateStat()
            
//         }
//         else if(tenderStat === "failed"){
//             updateStat()
//         }

//         if(!hasEffectRun.current && tenderStat === "succeeded"){
//             hasEffectRun.current = true;
//             toast.success("Data fetched successfully!")
//         }
//     }, [tenderStat, tenderStatDetail])

    
    

//     const updateStat = () => {
//         setStatData((prevState) => {
//             return prevState.map((stat) => {
//                 switch (stat.label) {
//                     case "Tender Number":
//                         return { ...stat, value: tenderStatDetail?.tender_id ?? "No Data" };
//                     case "Tender Name":
//                         return { ...stat, value: tenderStatDetail?.tender_name ?? "No Data" };
//                     case "Total Bidders":
//                         return { ...stat, value: tenderStatDetail?.total_bidder ?? "No Data" };
//                     case "Total Tender Documents":
//                         return { ...stat, value: tenderStatDetail?.total_tender_documents ?? "No Data" };
//                     case "Status":
//                         return { ...stat, value: tenderStatDetail?.status ?? "No Data" };
//                     default:
//                         return stat;
//                 }
                
//             })
//         })
//     }

//   return (
//     <div className='flex gap-20 shadow-md px-5 py-5 rounded-xl' style={{border: "1px solid rgba(0, 0, 0, 0.21)"}}>
        
//         {
//             statData.map((elem, i) => (
//                 <div key={i} className='flex flex-col gap-7'>
//                     <span className='text-[#727477] font-bold'>{elem.label}</span>
//                     {
//                         tenderStat==="loading" ? <Skeleton height={25}/>
//                         :
//                         <span className='text-[#2D3748] font-bold'>{elem.value}</span>
//                     }
//                 </div>
//             ))
//         }


//     </div>
//   )
// }

// export default TenderStats


import React, {useEffect, useState, useRef } from 'react'
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { updateTenderDocCount, fetchTenderStat } from '../../features/TenderDetail/TenderDetailSlice';
import { useParams } from 'react-router-dom';
import {tenderStatus} from "../../utils/TenderConstants"
import { Spinner, Tooltip } from "@material-tailwind/react";

const TenderStats = () => {
    const [statData, setStatData] = useState([
        {label: "Tender Number", value: ""},
        {label: "Tender Name", value: ""},
        {label: "Total Bidders", value: ""},
        {label: "Total Tender Documents", value: ""},
        {label: "Status", value: ""},
    ])
    let { id } = useParams();

    const {tenderStat, tenderStatDetail} = useSelector((state) => state.tenderDetail)
    console.log("tenderStatDetail: ", tenderStatDetail)


    const dispatch = useDispatch()
  
    useEffect(() => {
      dispatch(fetchTenderStat(id))
    }, [])

    const hasEffectRun = useRef(false);
    useEffect(() => {
        if(tenderStat === "succeeded"){
            updateStat()
            
        }
        else if(tenderStat === "failed"){
            updateStat()
        }

        if(!hasEffectRun.current && tenderStat === "succeeded"){
            hasEffectRun.current = true;
            toast.success("Data fetched successfully!")
        }
    }, [tenderStat, tenderStatDetail])

    
    

    const updateStat = () => {
        setStatData((prevState) => {
            return prevState.map((stat) => {
                switch (stat.label) {
                    case "Tender Number":
                        return { ...stat, value: tenderStatDetail?.tender_id ?? "No Data" };
                    case "Tender Name":
                        return { ...stat, value: tenderStatDetail?.tender_name ?? "No Data" };
                    case "Total Bidders":
                        return { ...stat, value: tenderStatDetail?.total_bidder ?? "No Data" };
                    case "Total Tender Documents":
                        return { ...stat, value: tenderStatDetail?.total_tender_documents ?? "No Data" };
                    case "Status":
                        return { ...stat, value: tenderStatDetail?.status ?? "No Data" };
                    default:
                        return stat;
                }
                
            })
        })
    }

  return (
    <div className='flex flex-col lg:flex-row gap-4 items-center justify-between bg-[#FFFFFF] shadow-md px-5 py-2 rounded-xl' style={{border: "1px solid rgba(0, 0, 0, 0.21)"}}>
       <div className="flex flex-col gap-2">
          <div className="font-bold text-[#242325] text-lg flex gap-2 items-center">
            <span>
                {
                    tenderStat === "loading" ? 
                    "Loading Tender Name..."
                    :
                    `${statData[1].value} -`
                }
            </span>
            <span>
                {
                    tenderStat === "loading" ? 
                    <Spinner className="h-4 w-4" />
                    :
                    statData[0].value
                }
            </span>
          </div>
          <div className="flex gap-4 text-base text-[#737373]">
            <div className="flex gap-2 items-center">
                <span>Total Bidders: </span>
                <span className='font-bold'>
                    {
                        tenderStat === "loading" ? 
                        <Spinner className="h-4 w-4" />
                        :
                        statData[2].value
                    }
                </span>
            </div>
            <div className="flex gap-2 items-center">
                <span>Total Tender Documents: </span>
                <span className='font-bold'>
                    {
                        tenderStat === "loading" ? 
                        <Spinner className="h-4 w-4" />
                        :
                        statData[3].value
                    }
                </span>
            </div>
          </div>
       </div>

       <div className="flex gap-6">
        <div className="flex items-center gap-2">
            <span className='text-[#737373] font-bold'>Status: </span>
            <span>
                {
                tenderStatDetail?.status===tenderStatus.consolidatedStatementGenerated ?
                "100%"
                :
                "52%"
                }
            </span>
        </div>
        <div className="p-2 border rounded-lg bg-[#E5F7F8] shadow-md text-[#242325] font-medium text-center min-w-[100px] flex items-center justify-center">
            {
                tenderStat === "loading" ? 
                <Spinner className="h-5 w-5" />
                :
                tenderStatDetail?.status===tenderStatus.consolidatedStatementGenerating ?
                "In Progress"
                :
                tenderStatDetail?.status === tenderStatus.consolidatedStatementGenerated &&
                "Completed"
            }
        </div>
       </div>

    </div>
  )
}

export default TenderStats