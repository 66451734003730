import React, { useEffect } from "react";
import HomeTable from "../../container/HomeContainer/HomeTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenderDetail } from "../../features/Tender/TenderSlice";

const InProgressPage = () => {
  const { in_progress } = useSelector((state) => state.tender);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTenderDetail("in_progress"));
  }, []);
  return (
    <div className="w-full h-full flex flex-col gap-[30px]">
      <div className="flex-none pl-4 pr-4 flex items-center justify-between">
        <span className="text-[#8d6641] font-bold text-2xl"> In Progress </span>
        <span className="text-[#33ACFF] font-bold">Count: {in_progress.data?.length} </span>
      </div>
      <div className="flex-grow w-full overflow-y-auto shadow-xl bg-[#FFFFFF]">
        <HomeTable data={in_progress.data} status={in_progress.loading} />
      </div>
    </div>
  );
};

export default InProgressPage;
