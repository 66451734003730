import React from "react";
import { Breadcrumbs } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

function TenderBreadCrumb() {
  const {tenderStat, tenderStatDetail} = useSelector((state) => state.tenderDetail)
  const navigate = useNavigate()

  return (
    <div>
      <Breadcrumbs className="bg-[none] text-[#767676] font-bold text-xl">
        <div className="text-[blue] font-bold text-base" onClick={() => navigate("/all-tenders")}>
          All
        </div>
        <div  className="text-[blue] font-bold text-base">
          {tenderStatDetail?.["tender_name"]}
        </div>
      </Breadcrumbs>
    </div>
  );
}

export default TenderBreadCrumb;
