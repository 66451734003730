import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Alert
} from "@material-tailwind/react";
import { MdArrowBackIosNew } from "react-icons/md";
import { toast, Toaster } from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { manualCreateTender, updateTenderCount } from "../../features/Tender/TenderSlice";
import { useDispatch, useSelector } from "react-redux";

export function SelectManualModal({
  manualModalStatus,
  setManualModalStatus,
  setSelectOptionStatus,
}) {

  const dispatch = useDispatch()

  const backendLabels = {
    tenderName: "tender_name", 
    tenderID: "tender_number", 
    tenderDocuments: "tender_documents", 
    bidderDocuments: "bidder_documents"
  }

  const [payload, setPayload] = useState({
    [backendLabels.tenderName]: "",
    [backendLabels.tenderID]: "",
    [backendLabels.tenderDocuments]: {
      path: "",
      file: null
    },
    [backendLabels.bidderDocuments]: {
      path: "",
      file: null
    },
  })

  const [errors, setErrors] = useState({})
  
  // Reset Values
  useEffect(() => {
    if (manualModalStatus) {
      resetValues()
    }
  }, [manualModalStatus]);

  const resetValues = () => {
    setErrors({})
      setAllEntryFilled("")
      setPayload({
        [backendLabels.tenderName]: "",
        [backendLabels.tenderID]: "",
        [backendLabels.tenderDocuments]: {
          path: "",
          file: null
        },
        [backendLabels.bidderDocuments]: {
          path: "",
          file: null
        },
      })

  }
  
  
  const handleOpen = (value) => setManualModalStatus(value);

  const handleBack = () => {
    setManualModalStatus(false);
    setSelectOptionStatus(true);
  };

  const validateInput = (name, value, type) => {
    let error = ""
    switch (name) {
      case backendLabels.tenderName:
        if(!value) {
          error = "Tender name is required"
        }
        if(value.length>250){
          error = "Tender name is too long"
        }
        break;
        case backendLabels.tenderID:
          if(!value) {
            error = "Tender Number is required"
          }
          break;
      case backendLabels.tenderDocuments:
        if(type === "file" && (!value || value.length === 0)) {
          error = "Tender docs is required"
        }
        break;
      case backendLabels.bidderDocuments:
        if(type === "file" && (!value || value.length === 0)) {
          error = "Bidder docs is required"
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  }

  const handleBlur = (e) => {
    const { name, value, type, files } = e.target;
    validateInput(name, value, type);
  };

  const handleFocus = (e) => {
    const { name, type, value } = e.target;
    setAllEntryFilled("")
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;

    if (type === "file") {
      if(files.length>0){
        setPayload((prevData) => ({
          ...prevData,
          [name]: {
            path: value,
            file: files[0]
          }
        }));
      }
      else{
        setPayload((prevData) => ({
          ...prevData,
          [name]: {
            path: "",
            file: null
          }
        }));
      }
    } 
    else{
      validateInput(name, value);
      setPayload((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const [allEntryFilled, setAllEntryFilled] = useState("")

  const validateCompleteForm = () => {
    for (const key in errors) {
      if (errors[key].length>0) {
        return true
      }
    }

    if(payload?.[backendLabels.tenderName?.length === 0]){
      return true
    }
    else if(payload?.[backendLabels.tenderDocuments]?.path?.length === 0){
      return true
    }
    else if(payload?.[backendLabels.bidderDocuments]?.path?.length === 0){
      return true
    }
    else{
      return false
    }
  }


  const submitHandler = () => {
    const formIsInvalid = validateCompleteForm()
    if(formIsInvalid){
      setAllEntryFilled(`All fields with * are required`) 
    }
    else{
      callingSubmitAPI()
    }
  }


  const [dataUploading, setDataUploading] = useState(false)
  const callingSubmitAPI = async() => {
    try {
      setDataUploading(true);
      const formData = new FormData();
      for (const key in payload) {
          if (payload[key]?.file) {
              formData.append(key, payload[key].file, payload[key].path);
          } else {
              formData.append(key, payload[key]);
          }
      }
      const resultAction = await dispatch(manualCreateTender(formData));
      const data = unwrapResult(resultAction);
      toast.success('Tender uploaded successfully')
      dispatch(updateTenderCount({flag: "new_tender"}))
      setManualModalStatus(false)
    } 
    catch(error){
      console.log(error)
      toast.error(error?.error ?? "Something went wrong. Please try again later")
    }
    finally {
      setDataUploading(false); 
    }

  }


  return (
    <>
      <Dialog open={manualModalStatus} handler={handleOpen}
      dismiss={{
        outsidePress: false,
      }}
      > 
      <Toaster position="top-center" />
       
        <div className="flex items-center justify-start gap-40">
          
          <div onClick={handleBack} className="cursor-pointer ml-2">
            <MdArrowBackIosNew  size={25}/>
          </div>
         
          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            Upload File
          </DialogHeader>
        </div>

        <DialogBody>
          <form>

          <div class="mb-3">
            <label
              for={backendLabels.tenderName}
              class="mb-2 mt-2 inline-block text-black dark:text-neutral-400 text-sm"
            >
              Enter Tender Name
              <span className="text-red-500 text-lg">*</span>
            </label>
            <input
              class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
              style={{border: "1px solid rgba(0, 0, 0, 0.6)"}}
              type="text"
              id={backendLabels.tenderName}
              name={backendLabels.tenderName}
              placeholder="Enter Tender Name"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
              disabled={dataUploading}
            />

            {
              errors?.[backendLabels.tenderName]?.length>0 &&
              <div className="text-[red] text-xs mt-2">
                {errors?.[backendLabels.tenderName]}
              </div>
            }
          </div>

          <div class="mb-3">
            <label
              for={backendLabels.tenderID}
              class="mb-2 mt-2 inline-block text-black dark:text-neutral-400 text-sm"
            >
              Enter Tender Number 
              <span className="text-red-500 text-lg">*</span>
            </label>
            <input
              class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
              style={{border: "1px solid rgba(0, 0, 0, 0.6)"}}
              type="text"
              id={backendLabels.tenderID}
              name={backendLabels.tenderID}
              placeholder="Enter Tender Number"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
              disabled={dataUploading}
            />

            {
              errors?.[backendLabels.tenderID]?.length>0 &&
              <div className="text-[red] text-xs mt-2">
                {errors?.[backendLabels.tenderID]}
              </div>
            }
          </div>

          <div class="mb-3">
            <label
              for={backendLabels.tenderDocuments}
              class="mb-2 inline-block text-black dark:text-neutral-400 text-sm"
            >
              {/* Tender Document (.docx or .pdf or zip only) */}
              Tender Document (.pdf or zip only)
              <span className="text-red-500 text-lg">*</span>
            </label>
            <input
              className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
              style={{border: "1px solid rgba(0, 0, 0, 0.6)"}}
              type="file"
              accept=".docx,.pdf,.zip"
              id={backendLabels.tenderDocuments}
              name={backendLabels.tenderDocuments}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
              disabled={dataUploading}
            />
            
            {
              errors?.[backendLabels.tenderDocuments]?.length>0 &&
              <div className="text-[red] text-xs mt-2">
                {errors?.[backendLabels.tenderDocuments]}
              </div>
            }
          </div>

          <div class="mb-3">
            <label
              for={backendLabels.bidderDocuments}
              class="mb-2 mt-2 inline-block text-black dark:text-neutral-400 text-sm"
            >
              Bidder Document (zip only)
              <span className="text-red-500 text-lg">*</span>
            </label>
            <input
              class="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
              style={{border: "1px solid rgba(0, 0, 0, 0.6)"}}
              type="file"
              accept=".docx,.pdf,.zip"
              id={backendLabels.bidderDocuments}
              name={backendLabels.bidderDocuments}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
              disabled={dataUploading}
            />

            {
              errors?.[backendLabels.bidderDocuments]?.length>0 &&
              <div className="text-[red] text-xs mt-2">
                {errors?.[backendLabels.bidderDocuments]}
              </div>
            }
          </div>

          </form>

          {
            allEntryFilled.length>0 &&
            <div className="text-center text-[red] text-sm mt-2">
              {allEntryFilled}
            </div>
          }
        </DialogBody>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(false)}
            className="mr-1"
            disabled={dataUploading}
          >
            <span>Cancel</span>
          </Button>

          <Button
            variant="gradient"
            color="green"
            onClick={submitHandler}
            loading={dataUploading}
          >
            <span>Upload</span>
          </Button>
        </DialogFooter>

      </Dialog>
    </>
  );
}
