import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Checkbox,
  Typography,
  Alert
} from "@material-tailwind/react";
import { CiCircleRemove } from "react-icons/ci";
import { AiOutlineDown } from "react-icons/ai"; 
import pqrLists from "../../utils/PqrLists";
import { useDispatch,useSelector } from "react-redux";
import { addPqr, updatePQRCount, editPQR } from "../../features/Tender/TenderSlice";

const SelectPqrModal = ({ pqrModalStatus, setPqrModalStatus, tenderId , selectedPqrModal, pqrVal}) => {
  const handleOpen = (value) => setPqrModalStatus(value);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { pqr_list , pqrfetchStatus} = useSelector(state => state.tender);
  
  const dispatch = useDispatch()

  useEffect(() => {
    if(!pqrModalStatus){
      resetValues()
    }
  }, [pqrModalStatus])

  const resetValues = () => {
    setSelectedOptions([])
    setIsDropdownOpen(false)
    setShowAlert(false)
  }

  const handleSubmit = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else if(selectedPqrModal === pqrVal.editPqr){
      setIsDropdownOpen(false)
      // updatePqrApi();
    }
    else {
      setIsDropdownOpen(false)
      addPqrApi();
   }
  };

  const handleUpdate = () => {
    if (selectedOptions.length === 0) {
      setShowAlert(true);
    } else {
      setIsDropdownOpen(false)
      updatePqrApi();
    }
  }

  const [addPqrApiCalling, setAddPqrApiCalling] = useState(false);
  const [alertMsg, setAlertMsg] = useState("")

  const [updatePqrApiCalling, setUpdatePqrApiCalling] = useState(false);

  const updatePqrApi = async() => {
    try {
      const payload = {
        "doc_id": tenderId,
        "pqrs": selectedOptions
      }
      setUpdatePqrApiCalling(true);
      // await dispatch(addPqr(payload));
      await dispatch(editPQR(payload));
      dispatch(updatePQRCount({doc_id: tenderId, new_count: selectedOptions.length, flag: "edit_pqr"}))
      setPqrModalStatus(false);
    } 
    catch (error) {
      setShowAlert(true); 
      setAlertMsg("Something went wrong")
      console.error(error);
    }
    finally {
      setUpdatePqrApiCalling(false); 
    }
  }

  const addPqrApi = async() =>{
    try {
      const payload = {
        "doc_id": tenderId,
        "pqr": selectedOptions
      }
      setAddPqrApiCalling(true);
      await dispatch(addPqr(payload));
      dispatch(updatePQRCount({doc_id: tenderId, new_count: selectedOptions.length, flag: "add_pqr"}))
      setPqrModalStatus(false);
    } 
    catch (error) {
      setShowAlert(true); 
      setAlertMsg("Something went wrong")
      console.error(error);
    }
    finally {
      setAddPqrApiCalling(false); 
    }
  }

  useEffect(() => {
    if(showAlert){
      setTimeout(() => {
        setShowAlert(false)
      }, 4000)
    }
  }, [showAlert])

  useEffect(() => {
    if(!pqrModalStatus){
      setSelectedOptions([])
    }
  }, [pqrModalStatus])

  useEffect(()=>{
    if(pqrfetchStatus==="succeeded"){
        setSelectedOptions(pqr_list);
    }
 },[pqrfetchStatus])


  const handleOptionClick = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const selectAll = () => {
    if (selectedOptions.length === pqrLists.length) {
      setSelectedOptions([]);
    } 
    else {
      setSelectedOptions(pqrLists.map((item) => item));
    }
  };

  const NoData = () => {
    return (
      <div className="h-[40vh] flex items-center justify-center">
        No PQR Selected
      </div>
    )
  }

  const LoadingData = () => {
    return (
      <div className="h-[40vh] flex items-center justify-center">
        Loading Data
      </div>
    )
  }

  const FailedData = () => {
    return (
      <div className="h-[40vh] flex items-center justify-center">
        Failed to fetch Data
      </div>
    )
  }

  const SelectedPqrList = () => {

    return (
      <div className={`flexabsolute rounded bg-white divide-y divide-black-700 mt-5 flex flex-col gap-1 shadow-lg border-gray-700 border overflow-y-scroll h-[40vh] pl-3`}>
              <div className="w-full flex items-center justify-center py-2 text-black text-lg font-bold">Selected PQRS</div>
              {selectedOptions.map((item, index) => (
                <div key={index} className="flex items-center justify-between ">
                  <Typography color="gray">{item}</Typography>
                  <Button
                    variant="text"
                    color="red"
                    onClick={!addPqrApiCalling ? () => setSelectedOptions(selectedOptions.filter((option) => option !== item)) : undefined}
                  >
                    <CiCircleRemove size={28} />
                  </Button>
                </div>
              ))}
      </div>
    )
  }

  const toggleRef = useRef(null);
  const DropdownList = () => {
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div ref={dropdownRef} className="border-gray-700 border absolute rounded bg-white divide-y divide-black-700 shadow-lg w-full mt-3 max-h-[42vh] overflow-y-auto">
                  <div className="flex items-center p-1 cursor-pointer hover:bg-gray-200 transition duration-200" onClick={selectAll}>
                    <Checkbox checked={selectedOptions.length === pqrLists.length} readOnly />
                    <span className="ml-2">Select all</span>
                  </div>
                  {
                    pqrLists.map((item, index) => (
                      <div
                        key={index}
                        className={`pl-3 py-1 pt-1 cursor-pointer hover:bg-gray-200 mt-2 border-none transition duration-200 ${
                          selectedOptions.includes(item) ? "bg-gray-200" : ""
                        }`}
                        onClick={!addPqrApiCalling ? () => handleOptionClick(item) : undefined}
                      >
                        {item}
                      </div>
                    ))
                  }
      </div>
    )
  }


  useEffect(() => {
    selectedOptions.length > 0 && setShowAlert(false);
  }, [selectedOptions]);

  return (
    <>
      <Dialog
        className= "min-h-[60vh]"
        open={pqrModalStatus}
        handler={handleOpen}
        dismiss={{
          outsidePress: false,
        }}
      >
        {showAlert && (
            <Alert
              open={showAlert}
              onClose={() => setShowAlert(false)}
              color="red"
              animate={{
                mount: { y: 0 },
                unmount: { y: 100 },
              }}
          > 
            {
              alertMsg.length>0 ? alertMsg : "Choose atleast one PQR"
            }
          </Alert>
        )}

        <div className="flex items-center justify-center gap-40">
          <DialogHeader className="text-black font-sans subpixel-antialiased place-content-center">
            {
              selectedPqrModal===pqrVal.editPqr ? 
              "Edit PQR" :
              "Select PQR"
            }
          </DialogHeader>
        </div>

        <DialogBody className="cursor-pointer">
          <div className="relative">

            <div
              ref={toggleRef}
              className="flex items-center border p-2 rounded cursor-pointer border-gray-700"
              onClick={!addPqrApiCalling ? () => setIsDropdownOpen(!isDropdownOpen) : undefined}
            >
              <div className="flex flex-wrap gap-1 ">
                {selectedOptions.length > 0 ? <span>{selectedOptions.length} selected</span> : <span className="text-gray-900">Select PQR</span>}
              </div>
              <AiOutlineDown className="ml-auto" />
            </div>

              {isDropdownOpen && <DropdownList />}

          </div>

          {
            (selectedPqrModal===pqrVal.editPqr && pqrfetchStatus === "loading") ? <LoadingData />
            :
            (selectedPqrModal===pqrVal.editPqr && pqrfetchStatus === "failed") ? <FailedData />
            :
            selectedOptions.length === 0 ?
            <NoData/>
            :

            isDropdownOpen ?
            <NoData />

            :

            <SelectedPqrList />
          
        }

        </DialogBody>

        <DialogFooter >
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(false)}
            className=" mr-1  "
            disabled={addPqrApiCalling || updatePqrApiCalling}
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            // onClick={handleSubmit}
            loading={addPqrApiCalling || updatePqrApiCalling}
          >
            {selectedPqrModal===pqrVal.editPqr ? <span onClick={handleUpdate}>Update</span> : <span onClick={handleSubmit} >Submit</span>}
          
          </Button>
          
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default SelectPqrModal;
